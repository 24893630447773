<template>
    <v-container fluid class="pb-0">
        <div v-if="selected_till && selected_till.status=='abierta'">
            <template>
                <v-row class="h-100">
                    <v-col cols="8" class="pt-1 pb-0">
                        <Catalogue></Catalogue>
                    </v-col>
                    <v-col cols="4" class="pt-1 pb-0">
                        <Cart></Cart>
                    </v-col>
                </v-row>
            </template>
        </div>
        <div v-else>
            <closed-till></closed-till>
        </div>       
    </v-container>
</template>

<script>

import {mapState, mapActions} from 'vuex';

import Catalogue from '../../../components/sales/Catalogue'
import Cart from '../../../components/sales/Cart'
import ClosedTill from '../../../components/tills/ClosedTill';

export default {
    name: 'Newsale',
    components: {
        Catalogue,
        Cart,
        ClosedTill
    },
    data() {
        return {
            itemsBreadcrumbs: [
                {
                    text: 'Ventas',
                    disabled: true,
                    href: '',
                }
            ],
        }
    },
    computed: {
        ...mapState('sales', [
            'status_operation'
        ]),
        ...mapState('authentication', [
            'office_id',
            'till_user_id'
        ]),
        ...mapState('tills', [
            'tills',
            'selected_till'
        ])
    },
    methods: {
        ...mapActions('sales', [
            'run_set_status_operation'
        ]),
        ...mapActions('tills', [
            'getAvailable',
            'select_till'
        ]),
        ...mapActions('accounts', [
            'getAccounts',
        ]),
        handleCloseMessageValidation() {
            this.run_set_status_operation(false);
        },
        async getCurrentUserTill(){
            let filters = {
                till_id: this.till_user_id
            }
            await this.getAvailable(filters);
            if (this.tills.length > 0) {
                this.select_till(this.tills[0]);
            }
        }
    },
    created() {
        this.getCurrentUserTill();
        this.getAccounts();
    },
    mounted() {
        this.run_set_status_operation(false);
    }
}
</script>

<style scoped>

</style>